import {
  faEnvelope,
  faEye,
  faEyeSlash,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionFunctionArgs,
  json,
  LoaderFunctionArgs,
  redirect,
  TypedResponse,
} from "@remix-run/node";
import { Form, useActionData, useNavigation } from "@remix-run/react";
import React, { useEffect, useRef, useState } from "react";
import StyledLink from "~/components/Users/StyledLink";
import { createSession, getSession } from "~/session.server";
import { safeRedirect } from "~/utils";
import ButtonSpinner from "~/components/ButtonSpinner";
import { loginApi } from "~/openapi.server";

/**
 * These are the form input keys, so they don't accidentally get out of sync.
 */
enum FormFields {
  email = "email",
  password = "password",
}

type LoginResponse = {
  authenticationToken?: string;
  success: boolean;
  fieldErrors?: {
    [keys in FormFields | "server"]?: string;
  };
};

type ErrorResponse = {
  [keys in FormFields | "server"]?: string;
};

type ActionData = {
  errors: ErrorResponse;
};

export async function loader({ request }: LoaderFunctionArgs) {
  const session = await getSession(request);
  const userToken = session.data.userToken;
  const url = new URL(request.url);
  const userTokenQuery = url.searchParams.get("token") ?? undefined;

  if (userTokenQuery && userTokenQuery != userToken) {
    return createSession({
      request,
      token: userTokenQuery,
      remember: false,
      redirectTo: safeRedirect(url.pathname),
    }).catch((e) => {
      throw e;
    });
  }

  if (userToken) {
    return redirect("/events");
  }

  return null;
}

export async function action({
  request,
}: ActionFunctionArgs): Promise<TypedResponse<ActionData>> {
  const formData = await request.formData();
  const email = formData.get(FormFields.email)?.toString();
  const password = formData.get(FormFields.password)?.toString();
  const redirectTo = safeRedirect("/events");

  // Ensure form fields are not empty
  if (email == null || email == "" || password == null || password == "") {
    const fieldErrors: ErrorResponse = {};
    if (email == null || email == "") {
      fieldErrors.email = "Please enter your email";
    }
    if (password == null || password == "") {
      fieldErrors.password = "Please enter your password";
    }
    return json({
      errors: fieldErrors,
    });
  }

  const response: LoginResponse = await loginApi
    .login({
      reqLoginDTO: {
        email: email,
        password: password,
      },
    })
    .then((res) => ({
      success: true,
      authenticationToken: res.authenticationToken,
    }))
    .catch(async (err) => {
      if (
        err.response &&
        (err.response.status == 400 ||
          err.response.status == 401 ||
          err.response.status == 404)
      ) {
        const errorData = await parseError(err);
        return { success: false, fieldErrors: errorData };
      } else if (err.response) {
        throw err.response;
      } else {
        throw err;
      }
    });

  if (response.success && response.authenticationToken != null) {
    return createSession({
      request,
      token: response.authenticationToken,
      remember: false,
      redirectTo,
    }).catch((e) => {
      throw e;
    });
  } else if (response.fieldErrors != null) {
    return json({ errors: response.fieldErrors });
  } else {
    throw response;
  }
}

async function parseError(
  errorResponse: XMLHttpRequest
): Promise<ErrorResponse> {
  const response = await errorResponse.response.json();
  const errorMessage = response.message;

  if (errorMessage.includes("Password not match")) {
    return { password: "Password does not match" };
  } else if (errorMessage.includes("Missing user")) {
    return { email: "Email does not exist" };
  } else if (errorResponse.response.status == 404) {
    return { server: "Not found" };
  } else {
    return { server: "An error has occurred" };
  }
}

function LoginPage() {
  const [showPassword, setShowPassword] = useState(false);

  const navigation = useNavigation();
  const isLoading =
    navigation.state == "submitting" || navigation.state === "loading";
  const loginText =
    navigation.state === "submitting"
      ? "Please wait..."
      : navigation.state === "loading"
      ? "Success!"
      : "Login";
  const actionData = useActionData<ActionData>();

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (actionData?.errors.email) {
      emailRef.current?.focus();
    } else if (actionData?.errors.password) {
      passwordRef.current?.focus();
    }
  }, [actionData]);

  return (
    <div className="bg-[#C5C4C4]">
      <div className="flex h-screen w-screen items-center justify-center p-5 sm:p-0 ">
        <div className="relative flex w-full max-w-md flex-col items-center space-y-3 rounded bg-white px-10 pb-20 pt-5 shadow-md">
          {/* Logo */}
          <img src="/image/Logo.svg" alt="logo" className="h-fit w-fit" />

          {/* Login */}
          <h1 className="text-2xl font-medium">Login</h1>

          <Form
            method="POST"
            className="flex w-full flex-col items-center space-y-3"
          >
            {/* Email Input */}
            <div className="w-full">
              <div className="relative flex w-full items-center">
                <div className="absolute left-0 flex h-full items-center border-r px-4 text-[#DDDDDD]">
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <input
                  type={FormFields.email}
                  name={FormFields.email}
                  ref={emailRef}
                  required
                  className={`w-full rounded-md border px-4 py-2 pl-14 outline-none placeholder:text-[#DDDDDD] ${
                    actionData?.errors.email
                      ? "focus:border-red-400 focus:shadow-[0_0_5px_0_rgba(185,28,28,1)]"
                      : "focus:border-blue-400 focus:shadow-[0_0_5px_0_rgba(96,165,250,1)]"
                  }`}
                  placeholder="Email"
                />
              </div>

              {/* Tooltip to show error */}
              {actionData?.errors.email && (
                <div
                  className="w-full text-left text-sm text-red-700"
                  id="email-error"
                >
                  {actionData.errors.email}
                </div>
              )}
            </div>

            {/* Password Input */}
            <div className="w-full">
              <div className="relative flex w-full items-center">
                <div className="absolute left-0 flex h-full items-center border-r px-4 text-[#DDDDDD]">
                  <FontAwesomeIcon icon={faLock} />
                </div>
                <input
                  type={showPassword ? "text" : FormFields.password}
                  name={FormFields.password}
                  ref={passwordRef}
                  required
                  className={`w-full rounded-md border px-4 py-2 pl-14 outline-none placeholder:text-[#DDDDDD] ${
                    actionData?.errors.password
                      ? "focus:border-red-400 focus:shadow-[0_0_5px_0_rgba(185,28,28,1)]"
                      : "focus:border-blue-400 focus:shadow-[0_0_5px_0_rgba(96,165,250,1)]"
                  }`}
                  placeholder="Password"
                />
                <div className="absolute right-0 flex h-full items-center border-r px-4 text-[#DDDDDD]">
                  <FontAwesomeIcon
                    onClick={() => setShowPassword(!showPassword)}
                    icon={showPassword ? faEye : faEyeSlash}
                    className="h-5 w-5 cursor-pointer"
                  />
                </div>
              </div>

              {/* Tooltip to show error */}
              {actionData?.errors.password && (
                <div
                  className="w-full text-left text-sm text-red-700"
                  id="password-error"
                >
                  {actionData.errors.password}
                </div>
              )}
            </div>

            {/* Tooltip to show error */}
            {actionData?.errors.server && (
              <div
                className="w-full text-left text-sm text-red-700"
                id="server-error"
              >
                {actionData.errors.server}
              </div>
            )}

            {/* Forgot Password */}
            <div className="w-full text-right">
              <StyledLink to="/forgot-password">Forgot Password?</StyledLink>
            </div>

            {/* Login Button */}
            <div className="absolute bottom-0 flex h-12 w-full items-center justify-center rounded-b bg-[#F5F5F5]">
              <button
                type="submit"
                disabled={isLoading}
                className="w-2/5 rounded bg-[#0E437F] py-2 font-medium text-white transition-all duration-200 disabled:opacity-50"
              >
                {isLoading && navigation.state !== "loading" && (
                  <ButtonSpinner />
                )}
                {loginText}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
